*,
**::before,
**::after {
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat';
  background-color: #f5f5f5;
  margin: 0rem;
  font-size: 0.5rem;
}
